import { createStore } from 'vuex'
import json from '../assets/products.json'

export default createStore({
  state: {
   
      categorie: json.map((item) => {
          return item;
        })
      
    
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

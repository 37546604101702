<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
     <div>
     
       <div>
    <ul>
      <li v-for="item in categorie" v-bind:key="item.name">{{ item.name }}</li>
    </ul>
  </div>

    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
// import json from '../assets/products.json'

export default {
  name: 'Home',
  data() {
    return {
      items: [{ message: 'Foo' }, { message: 'Bar' }]
    }
  },
    computed: {
    categorie() {
      return this.$store.state.categorie;
    }
  },
  components: {
    HelloWorld
  },
  created() {
    console.log("created");
  },
}
</script>
